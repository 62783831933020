<template>
  <div>
    <b-row cols="8">
      <b-col lg="3" sm="2" md="3" v-for="(item, idx) in activeList" :key="idx">
        <b-link :to="item.to">
          <b-card class="text-center">
            <feather-icon
                size="50"
                :icon="item.icon"
                :class="`text-${item.iconColor} my-2`"
            />

            <div :class="`mb-25 font-weight-bolder text-primary`">
              {{ item.text }}
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar, BRow, BCol, BCard, BCardText, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
  },
  created() {
    const userData = getUserData()
    const { resourceList } = userData
    if (undefined !== resourceList) {
      resourceList.forEach(r => {
        if (r.type === 1) {
          this.list.forEach(l => {
            if (l.text === r.name) {
              this.activeList.push(l)
            }
          })
        }
      })
    }
  },
  data() {
    return {
      activeList: [],
      list: [
        {
          icon: 'LayersIcon',
          text: '企业信息',
          to: '/enterprise1',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
        },
        {
          icon: 'LayersIcon',
          text: '隐私组织',
          to: '/enterprise1/organization',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
        },
        {
          icon: 'LayersIcon',
          text: '隐私政策',
          to: '/enterprise1/policy',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
        },
        {
          icon: 'LayersIcon',
          text: '隐私声明',
          to: '/enterprise1/statement',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
        },
        // {
        //   icon: 'EyeIcon',
        //   text: '隐私政策',
        //   to: '/enterprise1/policy',
        //   iconColor: 'success',
        //   bgColor: 'info',
        //   textColor: 'white',
        // },
        {
          icon: 'CpuIcon',
          text: '产品管理',
          to: '/product',
          iconColor: 'success',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'CpuIcon',
          text: 'IT资产管理',
          to: '/asset',
          iconColor: 'success',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'CpuIcon',
          text: '第三方管理',
          to: '/thridParty',
          iconColor: 'success',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'CpuIcon',
          text: '业务活动',
          to: '/datamap-list',
          iconColor: 'success',
          bgColor: 'secondary',
          textColor: 'white',
        },
        {
          icon: 'FileTextIcon',
          text: '个人数据资产',
          to: '/datamap-fields',
          iconColor: 'primary',
          bgColor: 'success',
          textColor: 'white',
        },
        {
          icon: 'KeyIcon',
          text: 'PIA管理',
          to: '/pia-process',
          iconColor: 'success',
          bgColor: 'danger',
          textColor: 'white',
        },
        {
          icon: 'ClipboardIcon',
          text: 'Cookie管理',
          to: '/cookies-list',
          iconColor: 'primary',
          bgColor: 'primary',
          textColor: 'white',
        },
        {
          icon: 'ClipboardIcon',
          text: '模板管理',
          to: '/cookies-template',
          iconColor: 'primary',
          bgColor: 'primary',
          textColor: 'white',
        },
        {
          icon: 'ClipboardIcon',
          text: '网站配置',
          to: '/cookies-website',
          iconColor: 'primary',
          bgColor: 'primary',
          textColor: 'white',
        },
        {
          icon: 'UsersIcon',
          text: '渠道管理',
          to: '/cpm-channel',
          iconColor: 'danger',
          bgColor: 'info',
          textColor: 'white',
        },
        {
          icon: 'PieChartIcon',
          text: '统计分析',
          iconColor: 'primary',
          bgColor: 'warning',
          textColor: 'white',
          to: '/cpm-statistic',
        },
        {
          icon: 'BookOpenIcon',
          text: '法律合规管理',
          to: '/compliance-list',
          iconColor: 'primary',
          bgColor: 'primary',
          textColor: 'white',
        },
        {
          icon: 'MapIcon',
          text: '法律合规差距分析',
          to: '/compliance-analysis',
          iconColor: 'danger',
          bgColor: 'secondary',
          textColor: 'white',
        },
      ],
    }
  },
}
</script>

<style>
</style>
